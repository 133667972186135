import React from "react"
import {graphql} from "gatsby";
import Homepage from "../templates/pages/index";

export default ({data}) => <Homepage isEn={false}  data={data}/>

export const pageQuery = graphql`
    query HomepageBlog {
        blog: allMarkdownRemark {
            posts: nodes {
                frontmatter {
                    title
                    date
                    slug
                    author
                    excerpt
                    thumbnail
                    tag
                }
                html
            }
        }
    }
`
